import { Role, UserAuthType } from 'src/redux/reducers/user';

export async function copyToClipboard(text: string) {
  await navigator.clipboard.writeText(text);
}

export function getOutputUrl(output: Output, type: OutputType['type']) {
  switch (type) {
    case 'preview':
      return output.previewUrl;
    case 'sandbox':
      return output.sandboxUrl;
    default:
      return output.liveUrl;
  }
}

export function getHoursMinutesSeconds(timeISO: string): string {
  const time = timeISO?.split('.')[0];
  const timeSegments = time.split(':');
  const hoursMinutesSeconds = timeSegments.length === 3 ? timeSegments : [...timeSegments, '00'];

  return hoursMinutesSeconds.join(':');
}

export function getISODateTimeString(datetime: string | Date): string {
  return new Date(datetime).toISOString();
}

export function getISODateString(date?: string | Date): string {
  return new Date(date ?? '').toISOString().substring(0, 10);
}

export const toDatetimeLocal = (date: string | Date) => {
  if (!date) return { date: '', time: '', localDateTime: '' };

  const localDate = new Date(date);

  const YYYY = localDate.getFullYear().toString();
  const MM = (localDate.getMonth() + 1).toString().padStart(2, '0');
  const DD = localDate.getDate().toString().padStart(2, '0');
  const HH = localDate.getHours().toString().padStart(2, '0');
  const II = localDate.getMinutes().toString().padStart(2, '0');
  const SS = localDate.getSeconds().toString().padStart(2, '0');

  return {
    date: YYYY + '-' + MM + '-' + DD,
    time: HH + ':' + II + ':' + SS,
    localDateTime: YYYY + '-' + MM + '-' + DD + 'T' + HH + ':' + II + ':' + SS,
  };
};

export const base64Encode = (str: string) => Buffer.from(str, 'utf8').toString('base64');

export const base64Decode = (str: string) => Buffer.from(str, 'base64').toString('utf8');

export const HTTP_REQUEST_HEADER_CSRF_PROTECTION = 'x-mxmz-csrf-protection';

export const getTotalRoles = (auth: UserAuthType) => auth.roles?.length ?? 0;

export const getLogoutLabel = (auth?: UserAuthType) =>
  auth && (auth.workspace?.name ? `${auth.name} (${auth.workspace.name})` : auth.name);

export const hasRoles = (auth: UserAuthType, roles: Role[]) =>
  roles.every((role) => auth.roles?.includes(role));
