import { Icon, Loader, Text } from '@southfields-digital/mpxlive-components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StateType } from 'src/redux/reducers';
import {
  GraphicGetAllFromPackageStateType,
  REQUEST_GET_ALL_GRAPHICS_BY_RUNDOWN_ID,
} from 'src/redux/reducers/graphic';

import GraphicCanvasCount from '../GraphicCanvasCount';

import styles from './GraphicList.module.scss';

interface IGraphicListProps {
  handleGraphicSelected: (graphicId: string) => void;
  rundownId: string;
}

function GraphicList({ handleGraphicSelected, rundownId }: IGraphicListProps) {
  const dispatch = useDispatch();
  const { data, loading, error }: GraphicGetAllFromPackageStateType = useSelector(
    (state: StateType) => state.graphic.allFromRundown
  );

  useEffect(() => {
    dispatch({
      type: REQUEST_GET_ALL_GRAPHICS_BY_RUNDOWN_ID,
      payload: { rundownId },
    });
  }, [rundownId, dispatch]);

  if (error) return <div>{error}</div>;
  if (loading) return <Loader centeredFullscreen />;
  if (!data) return null;

  return (
    <ul className="pb-4">
      {data.map((graphic, index) => (
        <li
          key={index}
          className={styles.NavigationItem}
          onClick={() => handleGraphicSelected(graphic.id)}
        >
          {graphic.thumbnail && (
            <div className={styles.MediaObject}>
              <img
                src={graphic.thumbnail}
                alt={graphic.name}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.style.visibility = 'hidden';
                }}
              />
            </div>
          )}

          <div className={styles.Container}>
            <div className={styles.Content}>
              <Text as="h3" className={styles.GraphicName} color="light">
                {graphic.name}

                <GraphicCanvasCount graphic={graphic} maxLength={2} />
              </Text>

              <Text as="p" className={styles.Description} color="surface">
                {graphic.description}
              </Text>
            </div>

            <div className={styles.Action}>
              <Icon icon="arrow-right" size={18} weight="regular" color={'#F5F6FF'} />
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default GraphicList;
