export default [
  {
    title: 'Rundowns',
    menuItems: [{ end: false, pathname: '/settings/templates', label: 'Templates' }],
  },
  {
    title: 'Workspace',
    menuItems: [{ end: false, pathname: '/settings/workspace/outputs', label: 'Manage outputs' }],
  },
  {
    title: 'Packages',
    menuItems: [{ end: false, pathname: '/settings/graphicpackages', label: 'Overview' }],
  },
  // @todo canvas management
  // {
  //   title: 'Canvases',
  //   menuItems: [{ end: false, pathname: '/settings/canvases', label: 'Canvases' }],
  // },
];
