import { Card, Input } from '@southfields-digital/mpxlive-components';
import { format } from 'date-fns';

interface IGraphicPackageCardProps {
  graphicPackage: GraphicPackage;
  onToggleDeprecate: (graphicPackage: GraphicPackage) => void;
}

const GraphicPackageCard = ({ graphicPackage, onToggleDeprecate }: IGraphicPackageCardProps) => (
  <Card
    headerContent={
      <div className="flex justify-between items-end">
        <div className="flex flex-col">
          <span className="block text-white font-medium">{graphicPackage.name}</span>
          <span className="text-sm text-gray-400">{graphicPackage.version}</span>
        </div>
        <div className="flex gap-4 items-center">
          <span className="text-sm text-gray-400">
            {format(new Date(graphicPackage.createdAt), 'dd-MM-yyyy')}
          </span>
          <span className="text-sm text-gray-400">
            {format(new Date(graphicPackage.updatedAt), 'dd-MM-yyyy')}
          </span>
          <Input
            type="toggle"
            size="sm"
            checked={!graphicPackage.deprecated}
            onChange={() => onToggleDeprecate(graphicPackage)}
          />
        </div>
      </div>
    }
  />
);

export default GraphicPackageCard;
