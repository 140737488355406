import { Button, Text } from '@southfields-digital/mpxlive-components';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRepeaterItems } from 'src/components/GraphicPropertiesForm';
import Repeater from 'src/components/Repeater';
import { Option, Value } from 'src/components/Select';
import { StateType } from 'src/redux/reducers';
import { REQUEST_GET_SPORT_DATA_LINEUP } from 'src/redux/reducers/sportData';
import { Player } from 'src/types/statsperform/lineup';

import SportDataInput from '../SportDataInput';
import SportDataSelect from '../SportDataSelect';
import { getPropertyValues } from '../utils';

import styles from './Lineup.module.scss';

type Props = {
  handleImportData: (values: PropertyValue[]) => void;
  selectedGraphic: PlaylistGraphic;
  selectedProperty: RepeaterProperties;
};

const filterPlayersByGraphic = (players: Player[], graphicRef: string): Player[] => {
  const positionFilter: { [key: string]: (player: Player) => boolean } = {
    LineupFull: (player) => !['Goalkeeper', 'Substitute'].includes(player.position),
    LineupTicker: (player) => player.position !== 'Substitute',
  };
  return players.filter(positionFilter[graphicRef] || (() => true));
};

export default function Lineup({ handleImportData, selectedGraphic, selectedProperty }: Props) {
  const dispatch = useDispatch();

  const { lineup } = useSelector((state: StateType) => state.sportData);

  const homeTeamName = lineup.data?.match?.[0]?.matchInfo?.contestant?.find(
    (e) => e.position === 'home'
  )?.name;

  const awayTeamName = lineup.data?.match?.[0]?.matchInfo?.contestant?.find(
    (e) => e.position === 'away'
  )?.name;

  const contestantOptions = useMemo<Option[]>(
    () => [
      { value: 0, label: homeTeamName ?? 'Home' },
      { value: 1, label: awayTeamName ?? 'Away' },
    ],
    [homeTeamName, awayTeamName]
  );

  const [fixtureId, setFixtureId] = useState<string>('');
  const [selectedContestant, setSelectedContestant] = useState<Value>(contestantOptions[0].value);

  const propertyValues = useMemo(() => {
    const contestantIndex = selectedContestant as number;
    const players = lineup.data?.match?.[0]?.liveData?.lineUp?.[contestantIndex]?.player ?? [];
    const filteredPlayers = filterPlayersByGraphic(players, selectedGraphic.graphic.ref);

    return getPropertyValues(selectedGraphic, filteredPlayers, []);
  }, [lineup.data, selectedContestant, selectedGraphic]);

  const repeaterValues = useMemo(
    () => getRepeaterItems(selectedProperty, propertyValues),
    [propertyValues, selectedProperty]
  );

  const isLoading = lineup.loading;
  const hasError = !!lineup.error;
  const hasData = repeaterValues.length > 0;

  const handleFixtureIdChange = useCallback((value: string) => setFixtureId(value.trim()), []);

  useEffect(() => {
    if (!fixtureId) return;
    dispatch({ type: REQUEST_GET_SPORT_DATA_LINEUP, payload: { fx: fixtureId } });
  }, [dispatch, fixtureId]);

  const handleImportClick = useCallback(
    () => handleImportData(propertyValues),
    [handleImportData, propertyValues]
  );

  return (
    <div className={styles.Container}>
      <SportDataInput
        fixtureId={fixtureId}
        onChange={handleFixtureIdChange}
        hasError={hasError && !isLoading && !fixtureId}
      />
      {!hasData && !isLoading && !hasError && (
        <Text color="surface" className="mt-2">
          No data available
        </Text>
      )}
      {hasData && !isLoading && !hasError && (
        <div className={styles.Content}>
          <div className={styles.Header}>
            <div className={styles.InputWrapper}>
              <SportDataSelect
                label="Contestant"
                value={selectedContestant}
                options={contestantOptions}
                onChange={(e) => setSelectedContestant(Number(e.currentTarget.value))}
              />
            </div>
            <Button className={styles.ImportButton} onClick={handleImportClick}>
              Import
            </Button>
          </div>
          <Repeater
            id="lineupRepeater"
            onChangeRows={() => {}}
            items={repeaterValues}
            isDisabled
            excludedIds={['divider', 'highlight']}
          />
        </div>
      )}
    </div>
  );
}
