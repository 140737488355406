// @todo this needs to be typed correctly, is now just object in the response
export type ActiveGraphics = object;

export type GraphicPackagesType = GraphicPackage[];
export type GraphicPackageType = GraphicPackage;

export type GraphicPackagesResponseType = GraphicPackagesType;
export type GraphicPackageResponseType = GraphicPackageType;

export type GraphicPackageCollectionStateType = {
  data: GraphicPackage[] | null;
  loading: boolean;
  error: string | null;
};

export type GraphicPackageStateType = {
  collection: GraphicPackageCollectionStateType;
};

export const GRAPHIC_PACKAGE = 'graphicPackage';
export type GRAPHIC_PACKAGE = typeof GRAPHIC_PACKAGE;

export const REQUEST_GET_GRAPHIC_PACKAGES = `${GRAPHIC_PACKAGE}/requestGetGraphicPackages`;
export type REQUEST_GET_GRAPHIC_PACKAGES = typeof REQUEST_GET_GRAPHIC_PACKAGES;

export const REQUEST_DEPRECATE_GRAPHIC_PACKAGE = `${GRAPHIC_PACKAGE}/requestDeprecateGraphicPackage`;
export type REQUEST_DEPRECATE_GRAPHIC_PACKAGE = typeof REQUEST_DEPRECATE_GRAPHIC_PACKAGE;
