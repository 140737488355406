import { FormGroup, Input, Text } from '@southfields-digital/mpxlive-components';
import { ChangeEvent } from 'react';

type SportDataInputProps = {
  fixtureId: string;
  onChange: (value: string) => void;
  hasError?: boolean;
};

export default function SportDataInput({ fixtureId, onChange, hasError }: SportDataInputProps) {
  return (
    <FormGroup label="Match Id" htmlFor="matchId">
      <Input
        hasError={hasError}
        id="matchId"
        value={fixtureId}
        placeholder="Statsperform Match Id"
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.currentTarget.value)}
      />
      {hasError && (
        <Text color="danger" className="text-sm px-3 opacity-90">
          Invalid match id
        </Text>
      )}
    </FormGroup>
  );
}
