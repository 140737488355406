import {
  Navbar,
  Sidebar,
  SidebarMenuItem,
  SidebarMenuItems,
} from '@southfields-digital/mpxlive-components';
import { IconNames } from '@southfields-digital/mpxlive-components/build/components/Icon';
import classNames from 'classnames';
import { useContext, useState, useMemo, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import DateInput from 'src/components/DateInput';
import GoLive from 'src/components/GoLive';
import LiveClock from 'src/components/LiveClock';
import LiveRundowns from 'src/components/LiveRundowns';
import RundownHeader from 'src/components/RundownHeader/RundownHeader';
import RundownSettings from 'src/components/RundownSettings';
import { StateType } from 'src/redux/reducers';
import { REQUEST_GET_LIVE_RUNDOWNS } from 'src/redux/reducers/rundown';

import { routeMap } from '../config/routes';
import { REQUEST_USER_LOGOUT, RoleEnum, UserAuthType } from '../redux/reducers/user';
import { getLogoutLabel, getTotalRoles, hasRoles } from 'src/utils';

import styles from './BaseLayout.module.scss';
import type { BaseLayoutContextType } from './BaseLayout.types';

export const BaseLayoutContext = createContext<BaseLayoutContextType | null>(null);

const Container = ({
  children,
  withContainer,
}: {
  children: React.ReactNode;
  withContainer: boolean;
}) =>
  !withContainer ? (
    <>{children}</>
  ) : (
    <div className={styles.Container}>
      <div className={styles.ContainerInner}>{children}</div>
    </div>
  );

export default function BaseLayout({
  auth,
  children,
}: {
  auth?: UserAuthType;
  children?: React.ReactNode;
}) {
  const editorFrontendUrl = import.meta.env.VITE_SERVICE_GRAPHICS_EDITOR_URL;

  const [currentlyOpenRundownId, setCurrentlyOpenRundownId] = useState<string | undefined>(
    undefined
  );
  const [fullWidth, setFullWidth] = useState(false);
  const [withContainer, setWithContainer] = useState(false);
  const [displayClock, setDisplayClock] = useState(false);
  const [goLive, setGoLive] = useState(false);
  const [showDateinput, setShowDateInput] = useState(false);
  const [showRundownName, setShowRundownName] = useState(false);
  const [showRundowSettings, setShowRundownSettings] = useState(false);

  const logoutLabel = useMemo(() => getLogoutLabel(auth), [auth]);

  const showMenuItems = useMemo(() => {
    if (!auth) {
      return false;
    }

    if (getTotalRoles(auth) === 1) {
      return hasRoles(auth, [RoleEnum.Superadmin]);
    }

    return (
      hasRoles(auth, [RoleEnum.Designer, RoleEnum.Operator]) ||
      hasRoles(auth, [RoleEnum.Designer, RoleEnum.Admin])
    );
  }, [auth, hasRoles, getTotalRoles]);

  const dispatch = useDispatch();
  const liveRundowns = useSelector((state: StateType) => state.rundown.live);

  const menuItems = routeMap
    ?.filter(({ position }) => position === 'sidebar')
    ?.map(
      (menuItem): SidebarMenuItem => ({
        icon: menuItem.icon as IconNames,
        pathname: menuItem.path as string,
      })
    );

  return (
    <div className={styles.Root}>
      <BaseLayoutContext.Provider
        value={{
          setCurrentlyOpenRundownId,
          setFullWidth,
          setWithContainer,
          setDisplayClock,
          setGoLive,
          setShowDateInput,
          setShowRundownName,
          setShowRundownSettings,
        }}
      >
        <Navbar
          links={[
            {
              to: '/',
              label: 'Operator',
              isActive: true,
              isHidden: !showMenuItems,
            },
            {
              to: editorFrontendUrl,
              label: 'Designer',
              isActive: false,
              isHidden: !showMenuItems,
            },
          ]}
          isAuthenticated
          className={styles.Navbar}
          contentCenter={<div>{showRundownName && <RundownHeader />}</div>}
          contentRight={
            <div className="flex items-center gap-x-3">
              {goLive && <GoLive />}
              {displayClock && <LiveClock />}
              {showDateinput && <DateInput />}
              {showRundowSettings && <RundownSettings />}

              <LiveRundowns
                currentlyOpenRundownId={currentlyOpenRundownId}
                liveRundowns={liveRundowns}
                getLiveRundowns={() => dispatch({ type: REQUEST_GET_LIVE_RUNDOWNS })}
              />
            </div>
          }
        />

        <main className={styles.Main}>
          <Sidebar
            menuItems={menuItems as SidebarMenuItems}
            className={styles.Sidebar}
            isAuthenticated
            logoutLabel={logoutLabel}
            handleLogout={() => dispatch({ type: REQUEST_USER_LOGOUT })}
          />

          <div
            className={classNames(styles.View, {
              [styles.FullWidthView]: fullWidth,
              [styles.ContainedView]: withContainer,
            })}
          >
            <Container withContainer={withContainer}>{children || <Outlet />}</Container>
          </div>
        </main>
      </BaseLayoutContext.Provider>
    </div>
  );
}

export const injectBaseLayoutContext = (WrappedComponent: React.FC<any>) => {
  const WithBaseLayoutContext = (props: any) => {
    const baseLayoutContext = useContext<BaseLayoutContextType | null>(
      BaseLayoutContext
    ) as BaseLayoutContextType;

    return <WrappedComponent baseLayoutContext={baseLayoutContext} {...props} />;
  };

  return WithBaseLayoutContext;
};

export * from './BaseLayout.types';
