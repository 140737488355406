import { SettingsForm, Text } from '@southfields-digital/mpxlive-components';
import { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BaseLayoutContext, BaseLayoutContextType } from 'src/layouts/BaseLayout';
import menuGroups from 'src/pages/Settings/menuGroups';
import GraphicPackageCard from './GraphicPackageCard';
import { StateType } from 'src/redux/reducers';
import {
  REQUEST_DEPRECATE_GRAPHIC_PACKAGE,
  REQUEST_GET_GRAPHIC_PACKAGES,
} from 'src/redux/reducers/graphicPackage';

import styles from './GraphicPackageOverview.module.scss';

export default function GraphicPackageOverview() {
  const { setWithContainer } = useContext(BaseLayoutContext) as BaseLayoutContextType;
  const dispatch = useDispatch();
  const { data, error, loading } = useSelector(
    (state: StateType) => state.graphicPackage.collection
  );

  useEffect(() => {
    setWithContainer(true);
    dispatch({ type: REQUEST_GET_GRAPHIC_PACKAGES, payload: { deprecated: true } });

    return () => {
      setWithContainer(false);
    };
  }, [dispatch, setWithContainer]);

  const handleDeprecateGraphicPackage = (graphicPackage: GraphicPackage) => {
    dispatch({
      type: REQUEST_DEPRECATE_GRAPHIC_PACKAGE,
      payload: { id: graphicPackage.id, deprecated: !graphicPackage.deprecated },
    });
  };

  const renderGraphicPackages = useMemo(() => {
    if (!data) return <Text>No graphic packages found.</Text>;

    return data.map((graphicPackage: GraphicPackage) => (
      <GraphicPackageCard
        key={graphicPackage.id}
        graphicPackage={graphicPackage}
        onToggleDeprecate={handleDeprecateGraphicPackage}
      />
    ));
  }, [data]);

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error: {error}</Text>;

  return (
    <SettingsForm
      title="Graphic Packages"
      menuGroups={menuGroups}
      formSections={[
        {
          type: 'overview',
          overview: (
            <>
              <HeaderRow />
              <div className={styles.GraphicPackagesOverview}>{renderGraphicPackages}</div>
            </>
          ),
        },
      ]}
    />
  );
}

const HeaderRow = () => (
  <div className="flex justify-between px-3 py-2 bg-gray-900 text-sm text-gray-500">
    <span className="font-semibold">Package</span>
    <div className="flex gap-4">
      <span className="w-[69px]">Created</span>
      <span className="w-[69px]">Updated</span>
      <span>In use</span>
    </div>
  </div>
);
